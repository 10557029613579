<template>
    <BannerTemplate :bannerSource="bannerSource" :title="`Wachtwoord wijzigen`" :intro="``">
        <v-container>
            <v-row>
                <v-col cols="12" class="py-0">
                    <v-form ref="form" class="ma-2" v-if="isPasswordChanged != true"
                            v-model="valid"
                            lazy-validation>
                        <v-text-field
                                :append-icon="showOldPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                :rules="[rules.required, rules.password]"
                                required
                                color="primary"
                                name="oldPassword"
                                :type="showOldPassword ? 'text' : 'password'"
                                label="Oud wachtwoord*"
                                v-model="oldPassword"
                                :disabled="isSubmitting"
                                @click:append="showOldPassword = !showOldPassword">
                        </v-text-field>
                        <v-text-field
                                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                :rules="[rules.required, rules.password]"
                                required
                                color="primary"
                                name="password"
                                :type="showPassword ? 'text' : 'password'"
                                label="Nieuw wachtwoord*"
                                v-model="password"
                                :disabled="isSubmitting"
                                @click:append="showPassword = !showPassword">
                        </v-text-field>
                        <v-text-field
                                :append-icon="showRepeatPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                :rules="[rules.confirmPassword, rules.required]"
                                color="primary"
                                required
                                name="repeatPassword"
                                :type="showRepeatPassword ? 'text' : 'password'"
                                label="Nieuw wachtwoord bevestigen*"
                                v-model="repeatPassword"
                                request.StarRating
                                :disabled="isSubmitting"
                                @click:append="showRepeatPassword = !showRepeatPassword">
                        </v-text-field>

                        <v-btn color="primary" block @click="onChangePassword()" class="mt-2 mb-5" :disabled="isSubmitting">
                           Wijzig wachtwoord
                        </v-btn>
                    </v-form>
                    <div class="px-2" v-if="isPasswordChanged">
                        U heeft succesvol uw wachtwoord gewijzid.

                        <v-btn color="primary" block @click="goToMyDashboard()" class="mt-6 mb-5">
                            Ga naar Mijn Roadpursuit
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </BannerTemplate>
</template>

<script>
import { mapState } from 'vuex'
import BannerTemplate from '@/components/common/BannerTemplate';
import DataMixin from "@/mixins/data.mixin";
import RouteMixin from "@/mixins/route.mixin";
import MetaTagsMixin from "@/mixins/metatags.mixin";

export default {
    name: 'WachtwoordWijzigenDashboard',
    components: 
    {
        BannerTemplate
    },
    mixins: [DataMixin, RouteMixin, MetaTagsMixin],
    data: () => ({
        bannerSource: require('@/assets/album/Login/login-01.jpg'),
        isPasswordChanged: null,
        valid: true,
        oldPassword: '',
        password: '',
        repeatPassword: '',
        showOldPassword: false,
        showPassword: false,
        showRepeatPassword: false,
        isSubmitting: false
    }),
    computed: {
        ...mapState('authorizedModule', ['userId']),
    },
    mounted()
    {
        this.$store.commit('setFooterVisibility', true);
    },
    metaInfo() {
        return {
        title: '| Wachtwoord Wijzigen',
        meta: [
            this.getKeywords(`Wachtwoord Wijzigen`),
            this.getMetaDescription(`Roadpursuit Wachtwoord Wijzigen`),
            this.getAuthor(),
            ...this.getSocialMediaMetas(`Roadpursuit Wachtwoord Wijzigen`, `Roadpursuit Wachtwoord Wijzigen`, `${window.location.origin}${this.bannerSource}`, 'Wachtwoord Wijzigen'),
        ]
        };
    },
    methods: 
    {
        onChangePassword()
        {
            this.valid = this.$refs.form.validate()

            if(this.valid)
            {
                this.isSubmitting = true;
                const request = {
                    accountId: this.userId,
                    oldPassword: this.oldPassword,
                    newPassword: this.password
                }

                this.$store.dispatch('accountModule/changePasswordDashBoard', request)
                .then((data) => {
                    this.isPasswordChanged = data;
                    this.isSubmitting = false;
                })
                .catch(error => {
                    this.isPasswordChanged = false;
                    this.isSubmitting = false;
                    this.$store.commit('dialog/showDialog', error);
                })
              
            }
        }
    }
}
</script>
